import React from 'react'
import styled from 'styled-components'
import { breakpoints } from '../../utils/theme'

export const StyledProductGrid = styled.div`
  display: grid;
  justify-content: center;
  text-align: left;
  place-self: center;
  padding-bottom: 32px;

  grid-template-columns: ${({ homepage }) =>
    homepage ? "repeat(auto-fit, 25%)" : "25% 25% 25% 25%"};

  @media (max-width: ${breakpoints.tablet + 'px'}) {
    grid-template-columns: 50% 50%;
  }

  @media (max-width: ${breakpoints.mobile + 'px'}) {
    grid-template-columns: 50% 50%;
  }
`

const ProductGrid = ({ children, homepage }) => {
  return (
    <StyledProductGrid homepage={homepage}>
      {children}
    </StyledProductGrid>
  )
}

export default ProductGrid
